import Container from "../components/Container/Container";
import {Button, Card, Col, Flex, Form, Input, InputNumber, message, Row, Switch} from "antd";
import {SaveOutlined} from "@ant-design/icons";
import getConfigures from "../api/configure/getConfigures";
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";
import {useContext, useEffect} from "react";
import LoadingContext from "../contexts/LoadingContext";
import updateConfigures from "../api/configure/updateConfigures";

function Configure() {
    const [form] = Form.useForm();
    const {setLoading} = useContext(LoadingContext);
    const accessToken = useAuthHeader();
    const [messageApi, contextHolder] = message.useMessage();


    const items = [
        {
            label: 'Domain',
            type: 'text',
            name: 'domain'
        },
        {
            label: 'API Domain',
            type: 'text',
            name: 'apiDomain'
        },
        {
            label: 'Pay Domain',
            type: 'text',
            name: 'payDomain'
        },
        {
            label: 'Telegram',
            type: 'text',
            name: 'telegram'
        },
        {
            label: 'Memo chuyển',
            type: 'text',
            name: 'memo'
        },
        {
            label: 'Live Server',
            type: 'text',
            name: 'liveServer'
        },
        {
            label: 'Tần số quét (giây)',
            type: 'number',
            name: 'scanningFrequency'
        },
        {
            label: 'Giới hạn quét (giây)',
            type: 'number',
            name: 'scanLimit'
        },
        {
            label: 'Tiền Pay Out duyệt',
            type: 'number',
            name: 'payOutAmount'
        },
        {
            label: 'Tiền rút tự động',
            type: 'number',
            name: 'autoWithdrawAmount'
        },
        {
            label: 'Tối thiểu chuyển',
            type: 'number',
            name: 'minPayOut'
        },
        {
            label: 'Tối đa chuyển',
            type: 'number',
            name: 'maxPayOut'
        },
        {
            label: 'Thời gian cảnh báo OUT (phút)',
            type: 'number',
            name: 'payOutMinutes'
        }
    ];

    const switchs = [
        {
            label: 'Chặn IP',
            name: 'blockIp'
        },
        {
            label: 'Random tài khoản',
            name: 'randomAccount'
        },
        {
            label: 'Auto Pay Out',
            name: 'autoPayOut'
        },
        {
            label: 'Bảo trì Pay Out',
            name: 'payOutMaintenance'
        },
        {
            label: 'Bảo trì Pay In',
            name: 'payInMaintenance'
        },
        {
            label: 'Tự động rút tiền',
            name: 'autoWithdrawalEnabled'
        }
    ];

    const handleSubmit = async () => {
        setLoading(true);
        const response = await updateConfigures(accessToken,form.getFieldsValue());
        if (response.success) {
            message.success('Cập nhật cấu hình thành công');
        }
        setLoading(false);
    }

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const response = await getConfigures(accessToken);
            if (response.success) {
                const {data} = response;
                Object.entries(data).forEach(([key, value]) => {
                    form.setFieldValue(key,value);
                });
            }
            setLoading(false);
        }
        fetchData();
    }, []);
    return (
        <Container>
            {contextHolder}
            <Card
                title={'Cấu hình hệ thống'}
            >
                <Form
                    layout={'vertical'}
                    form={form}
                >
                    <Row gutter={16}>
                        {items.map((item,index) => {
                            const size =  item.type === 'text' ? 12 : 6;
                            return (
                                <Col key={index} xs={24} sm={24} md={size} lg={size} xl={size}>
                                    <Form.Item
                                        label={item.label}
                                        name={item.name}
                                        initialValue={form.getFieldValue(item.name)}
                                    >
                                        {item.type === 'text' ?
                                            (
                                                <Input
                                                    placeholder={'Nhập ' + item.label}
                                                />
                                            ) : (
                                            <InputNumber
                                                style={{
                                                    width: '100%'
                                                }}
                                                placeholder={'Nhập ' + item.label}
                                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                            )
                        })}
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Row gutter={[16]}>
                                {switchs.map((item,index) => {
                                    return (
                                        <Col
                                            key={index} xs={12} sm={12} md={4} lg={4} xl={4}
                                        >
                                            <Form.Item
                                                label={item.label}
                                                name={item.name}
                                            >
                                                <Switch onChange={(checked) => form.setFieldValue(item.name,checked)} defaultChecked={form.getFieldValue(item.name)}/>
                                            </Form.Item>
                                        </Col>
                                    )
                                })}
                            </Row>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Flex justify={'center'}>
                                <Button type={'primary'} icon={<SaveOutlined/>} onClick={handleSubmit}>Cập nhật</Button>
                            </Flex>
                        </Col>
                    </Row>
                </Form>
            </Card>
        </Container>
    )
}

export default Configure;