import {Button, Dropdown, Flex, Form, Input, message, Modal, QRCode, Space, Table, Tag, Typography} from "antd";
import {convertUnixTimestampToDateTime, formatDateTime, numberFormat} from "../../../helpers/helpers";
import AmountLimit from "../../AmountLimit/AmountLimit";
import CountLimit from "../../CountLimit/CountLimit";
import BankAccountType from "../BankAccountType/BankAccountType";
import {useContext, useEffect, useState} from "react";
import getBankAccounts from "../../../api/bank-account/getBankAccounts";
import handle from "../../../api/bank-account/handle";
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowsRotate, faMoneyBillTransfer} from "@fortawesome/free-solid-svg-icons";
import ItemDropDown from "../../TopMenu/ItemDropDown/ItemDropDown";
import {
    BankOutlined,
    EditOutlined,
    LoginOutlined,
    MobileOutlined,
    PhoneOutlined,
    QrcodeOutlined,
    TransactionOutlined, WalletOutlined
} from "@ant-design/icons";
import updateBank from "../../../api/bank/updateBank";
import EditTableCell from "../../Table/EditTableCell/EditTableCell";
import updateBankAccount from "../../../api/bank-account/updateBankAccount";
import Search from "../../Search/Search";
import TextCopy from "../../TextCopy";
import FormDrawer from "../../FormDrawer/FormDrawer";
import getBankWithdraw from "../../../api/withdraw/getBankWithdraw";
import getBankTransfer from "../../../api/bank-account/getBankTransfer";
import LoadingContext from "../../../contexts/LoadingContext";
import transfer from "../../../api/bank-account/transfer";
import getBenchName from "../../../api/bank-account/getBenchName";
import getBankAccountDevice from "../../../api/bank-account/device/getBankAccountDevice";
import updateBankAccountDevice from "../../../api/bank-account/device/updateBankAccountDevice";
import BankAccountTransaction from "../BankTransaction/BankAccountTransaction";
import ReadMoreText from "../../ReadMoreText";

function BankAccountTable({ banks, onRefresh, types = ['wallet'] }) {
    const [tableLoading,setTableLoading] = useState(false);
    const [dataSource,setDataSource] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const accessToken  = useAuthHeader();
    const [openModal,setOpenModal] = useState(false);
    const [qrCode,setQrCode] = useState('');
    const [openModalTransaction,setOpenModelTransaction] = useState(false);
    const [dataTransaction,setDataTransaction] = useState([]);
    const [form] = Form.useForm();
    const [editingKey, setEditingKey] = useState('');
    const [formSearch] = Form.useForm();
    const [openDrawerTransfer,setOpenDrawerTransfer] = useState(false);
    const [bankTransfer,setBankTransfer] = useState([]);
    const [uuid,setUuid] = useState('');
    const [bankAccountId, setBankAccountId] = useState('');
    const [formTransfer] = Form.useForm();
    const [formDevice] = Form.useForm();
    const [openModalDevice, setOpenModalDevice] = useState(false);
    const {setLoading} = useContext(LoadingContext);
    const [dataTransfer,setDataTransfer] = useState({});
    const [summaryData, setSummaryData] = useState({
        totalBalance: 0
    });
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
        sortField: undefined
    });

    const items =  [
        {
            name: 'username',
            placeholder: 'Tên đăng nhập',
            type: 'text'
        },
        {
            name: 'accountName',
            placeholder: 'Tên tài khoản',
            type: 'text'
        },
        {
            name: 'accountNumber',
            placeholder: 'Số tài khoản',
            type: 'text'
        },
        {
            name: 'createdAt',
            placeholder: 'Thời gian tạo',
            type: 'date'
        }
    ]

    const mergedItems = [
        {
            key: 'GET_BALANCE',
            label: (
                <ItemDropDown icon={<FontAwesomeIcon icon={faArrowsRotate}/>}>
                    Làm mới số dư
                </ItemDropDown>
            ),
        },
        {
            key: 'GET_TRANSACTION',
            label: (
                <ItemDropDown icon={<TransactionOutlined/>}>
                    Truy vấn giao dịch
                </ItemDropDown>
            ),
        },
        {
            key: 'RE_LOGIN',
            label: (
                <ItemDropDown icon={<LoginOutlined/>}>
                    Đăng nhập
                </ItemDropDown>
            )
        },
        {
            key: 'SHOW_QR',
            label: (
                <ItemDropDown icon={<QrcodeOutlined/>}>
                    QR Code
                </ItemDropDown>
            )
        }
    ]

    const columns = [
        {
            title: 'Mã',
            dataIndex: 'bankName',
            filters: banks,
            filterMode: 'tree',
            align: 'center',
            render: (value) => {
                return (
                    <Tag>{value}</Tag>
                )
            }
        },
        {
            title: 'Loại',
            dataIndex: 'bankType',
            align: 'center',
            filters: [
                {
                    text: 'Ví điện tử',
                    value: 'wallet'
                },
                {
                    text: 'Ngân hàng cá nhân',
                    value: 'bank'
                },
                {
                    text: 'Ngân hàng doanh nghiệp',
                    value: 'business'
                }
            ],
            render: (value) => {
                if (value === 'wallet') {
                    return <Tag icon={<WalletOutlined/>} color={'#ee5583'}>Ví điện tử</Tag>
                }else if (value === 'bank') {
                    return <Tag icon={<BankOutlined/>} color={'#55acee'}>Ngân hàng</Tag>
                }else if (value === 'business') {
                    return <Tag icon={<BankOutlined/>} color={'#ee7155'}>Doanh nghiệp</Tag>
                }
            }
        },
        {
            title: 'Tài khoản',
            dataIndex: 'username',
            width: '150px',
            align: 'center',
            render: (value) => {
                return <TextCopy value={value}/>
            }
        },
        {
            title: 'Thông tin',
            dataIndex: 'accountInfo',
            align: 'center',
            width: '200px',
            render: (value,record) => {
                return (
                    <Flex vertical={true} justify={'center'} align={'center'} gap={'small'}>
                        <ReadMoreText text={record.accountName} maxLength={15}/>
                        <TextCopy value={record.accountNumber}/>
                    </Flex>
                )
            }
        },
        {
            title: 'Số dư',
            dataIndex: 'balance',
            align: 'center',
            width: '130px',
            render: (value,record) => {
                return numberFormat(record.balance);
            },
            sorter: true
        },
        {
            title: 'Tổng nạp (ngày)',
            dataIndex: 'dailyReceiveAmount',
            render: (value,record) => {
                return <AmountLimit amount={record.dailyReceiveAmount} total={record.maxAmountLimitPerDay}/>
            },
            sorter: (a,b) => {
                return a.dailyReceiveAmount - b.dailyReceiveAmount
            }
        },
        {
            title: 'Tổng chuyển (ngày)',
            dataIndex: 'dailyTransferAmount',
            render: (value,record) => {
                return <AmountLimit amount={record.dailyTransferAmount} total={record.maxAmountLimitPerDay}/>
            },
            sorter: (a,b) => {
                return a.dailyTransferAmount - b.dailyTransferAmount
            }
        },
        {
            title: 'Số GD nhận (ngày)',
            dataIndex: 'dailyTotalReceives',
            render: (value,record) => {
                return <CountLimit count={record.dailyTotalReceives} total={record.dailyReceiveLimit}/>
            },
            sorter: (a,b) => {
                return a.dailyTotalRecevies - b.dailyTotalRecevies
            }
        },
        {
            title: 'Số GD chuyển (ngày)',
            dataIndex: 'dailyTotalTransfers',
            render: (value,record) => {
                return <CountLimit count={record.dailyTotalTransfers} total={record.dailyTransferLimit}/>
            },
            sorter: (a,b) => {
                return a.dailyTotalTransfers - b.dailyTotalTransfers
            }
        },
        {
            title: 'Loại tài khoản',
            dataIndex: 'type',
            align: 'center',
            filterMode: 'tree',
            editable: true,
            inputType: 'select',
            width: '120px',
            filters: [
                {
                    text: 'Nhận tiền',
                    value: 'in'
                },
                {
                    text: 'Chuyển tiền',
                    value: 'out'
                },
                {
                    text: 'Nhận và chuyển',
                    value: 'in_out'
                },
                {
                    text: 'Cho thuê',
                    value: 'hook'
                },
                {
                    text: 'Nhận tiền dồn',
                    value: 'main'
                }
            ],
            render: (value) => {
                return <BankAccountType type={value}/>
            }
        },
        {
            title: 'Proxy',
            dataIndex: 'proxy',
            align: 'center',
            editable: true,
            inputType: 'text',
            width: '340px',
            render: (value) => {
                if (value) {
                    return <TextCopy value={value}/>
                }
                return <Tag>Trống</Tag>
            }
        },
        {
            title: 'Trạng thái',
            dataIndex: 'status',
            width: '120px',
            filters: [
                {
                    text: 'Đóng',
                    value: false
                },
                {
                    text: 'Mở',
                    value: true
                }
            ],
            align: 'center',
            render: (value) => {
                if (value === true) {
                    return <Tag color={'green'}>OKE</Tag>
                }
                return <Tag color={'red'}>Lỗi</Tag>
            }
        },
        {
            title: 'Tự động rút',
            dataIndex: 'autoWithdrawalEnabled',
            width: '120px',
            editable: true,
            inputType: 'switch',
            filters: [
                {
                    text: 'Đóng',
                    value: false
                },
                {
                    text: 'Mở',
                    value: true
                }
            ],
            align: 'center',
            render: (value) => {
                if (value) {
                    return <Tag color={'green'}>Mở</Tag>
                }
                return <Tag color={'red'}>Đóng</Tag>
            }
        },
        {
            title: 'Hoạt động',
            dataIndex: 'state',
            editable: true,
            inputType: 'switch',
            align: 'center',
            width: '150px',
            filters: [
                {
                    text: 'Tắt',
                    value: 'disabled'
                },
                {
                    text: 'Bật',
                    value: 'active'
                }
            ],
            render: (value) => {
                if (value === 'disabled') {
                    return <Tag color={'red'}>Không hoạt động</Tag>
                }
                return <Tag color={'green'}>Hoạt động</Tag>
            }
        },
        {
            title: 'Ngày tạo',
            dataIndex: 'createdAt',
            width: '150px',
            render: (value) => {
                return formatDateTime(value);
            }
        },
        {
            title: 'Hành động',
            dataIndex: 'operation',
            render: (_,record) => {
                const items = mergedItems.map((col) => {
                    return {
                        ...col,
                        onClick: () => handleAccount(record.uuid,col.key)
                    }
                });
                const editable = isEditing(record);
                return editable ? (
                    <Space>
                        <Button
                            type={'primary'}
                            onClick={() => save(record.uuid)}
                            ghost={true}
                        >Lưu</Button>
                        <Button
                            type={'dashed'}
                            onClick={cancel}
                            danger={true}
                        >Hủy</Button>
                    </Space>
                ) : (
                    <Space>
                        <Dropdown.Button
                            placement={'bottom'}
                            menu={{
                                items
                            }}
                            type={'default'}
                            disabled={editingKey !== ''}
                            onClick={() => edit(record)}
                            title={'Chỉnh sửa'}
                        >
                            <EditOutlined/>
                        </Dropdown.Button>
                        <Button onClick={() => handleClickTransfer(record)} icon={<FontAwesomeIcon icon={faMoneyBillTransfer}/>} title={'Chuyển tiền'}/>
                        <Button onClick={() => handleClickDevice(record)} type={'primary'} icon={<MobileOutlined/>} title={'Thiết bị'}/>
                    </Space>
                )
            }
        }
    ];

    const handleClickTransfer = (record) => {
        setOpenDrawerTransfer(true);
        setUuid(record.uuid);
    }

    const handleClickDevice = async (record) => {
        const response = await getBankAccountDevice(accessToken, record.bankAccountId);
        if (response.success && response.data) {
            formDevice.setFieldsValue({
                deviceName: response.data?.deviceName,
                deviceId: response.data?.deviceId,
                pin: response.data?.pin,
                platformVersion: response.data?.platformVersion
            })
        }
        setOpenModalDevice(true);
        setBankAccountId(record.bankAccountId);
    }

    const handleSubmitDevice = async (values) => {
        setLoading(true);
        const response = await updateBankAccountDevice(accessToken, bankAccountId, values);
        if (response.success) {
            message.success('Cập nhật thiết bị thành công');
        }
        else {
            message.error(response.message);
        }
        setLoading(false);
    }

    const handleSubmitTransfer = async (values) => {
        try {
            setLoading(true);
            const response = await transfer(accessToken, uuid, {
                data: dataTransfer,
                ...values
            });
            if (response.success) {
                if (response.data.otp) {
                    setDataTransfer(response.data.data);
                    if (response.data.data.challenge) {
                        formTransfer.setFieldValue('challenge', response.data.data.challenge);
                    }
                    message.info('Vui lòng nhập mã OTP để xác nhận chuyển tiền');
                }
                else {
                    message.success('Chuyển tiền thành công!');
                }
            } else {
                message.error(response.message);
            }
        } catch (error) {
            message.error('Đã xảy ra lỗi trong quá trình chuyển tiền.');
        } finally {
            setLoading(false);
        }
    };

    const handleChangeTransfer = async (e) => {
        if (typeof e === 'string' || e.target.id === 'accountNumber') {
            const accountNumber = formTransfer.getFieldValue('accountNumber');
            const bankCode = formTransfer.getFieldValue('bankCode');
            if (bankCode && bankCode.length > 0) {
                const response = await getBenchName(accessToken, uuid, {
                    accountNumber: accountNumber,
                    bankCode: bankCode
                });
                if (response.success) {
                    formTransfer.setFieldValue('accountName', response.data.benchName);
                }
                else {
                    message.error(response.message);
                }
            }
        }
    }

    // const onSelectChange = (newSelectedRowKeys) => {
    //     setSelectedRowKeys(newSelectedRowKeys);
    // };
    // const rowSelection = {
    //     selectedRowKeys,
    //     onChange: onSelectChange,
    //     selections: [
    //         Table.SELECTION_ALL,
    //         Table.SELECTION_INVERT,
    //         Table.SELECTION_NONE
    //     ],
    // };

    const itemTransfer = [
        {
            name: 'accountNumber',
            placeholder: 'Nhập số tài khoản',
            type: 'text',
            label: 'Số tài khoản',
            required: true
        },
        {
            name: 'amount',
            placeholder: 'Nhập số tiền',
            type: 'number',
            label: 'Số tiền',
            required: true
        },
        {
            name: 'bankCode',
            placeholder: 'Chọn ngân hàng',
            type: 'select',
            label: 'Ngân hàng nhận',
            required: true,
            options: (bankTransfer).map((item) => {
                return {
                    value:  item.bankCode,
                    label: `${item.bankName} - ${item.displayName} (${item.shortName})`
                }
            })
        },
        {
            name: 'accountName',
            placeholder: 'Nhập tên người nhận',
            type: 'text',
            label: 'Tên người nhận',
            required: true,
            disabled: true
        },
        {
            name: 'comment',
            placeholder: 'Nhập nội dung',
            type: 'text',
            label: 'Nội dung',
            required: false
        },
        {
            name: 'challenge',
            placeholder: 'Mã giao dịch để lấy OTP',
            type: 'text',
            label: 'Mã giao dịch',
            disabled: true
        },
        {
            name: 'otp',
            placeholder: 'Nhập mã OTP',
            type: 'text',
            label: 'Mã OTP',
        }
    ];

    const cancel = () => {
        setEditingKey('');
    };

    const save =  async (uuid) => {
        setTableLoading(true);
        try {
            const row = await form.validateFields();
            const newData = [...dataSource];
            const index = newData.findIndex((item) => uuid === item.uuid);
            const data = {
                ...row,
                uuid: uuid
            };
            const response = await updateBankAccount(accessToken,data);
            if (response.success) {
                if (index > -1) {
                    const item = newData[index];
                    newData.splice(index, 1, {
                        ...item,
                        ...response.data
                    });
                    setDataSource(newData);
                    setEditingKey('');
                } else {
                    console.log(row);
                    newData.push(row);
                    setDataSource(newData);
                    setEditingKey('');
                }
            }
        } catch (e) {
            console.log('Validate Failed:', e);
        }
        finally {
            setTableLoading(false);
        }
    }

    const edit = (record) => {
        form.setFieldsValue({
            ...record,
            state: record.state === 'active'
        });
        setEditingKey(record.uuid);
    };

    const updateData = (uuid,data = {}) => {
        const newData = [...dataSource];
        const index = newData.findIndex((item) => uuid === item.uuid);
        if (index > -1) {
            const item = newData[index];
            newData.splice(index, 1, {
                ...item,
                ...data
            });
            setDataSource(newData);
        } else {
            newData.push(data);
            setDataSource(newData);
        }
    }

    const handleAccount = (uuid, cmd) => {
        const fetchData = async () => {
            setTableLoading(true);
            try {
                const response = await handle(accessToken,uuid, cmd);
                if (response.success) {
                    switch (cmd) {
                        case 'GET_BALANCE':
                        case 'RE_LOGIN':
                            updateData(uuid,response.data);
                            break;
                        case 'GET_TRANSACTION':
                            setOpenModelTransaction(true);
                            setDataTransaction(response.data);
                            break;
                        case 'SHOW_QR':
                            setOpenModal(true);
                            setQrCode(response.data.text);
                            break;
                    }
                }
            }
            catch (e) {}
            setTableLoading(false);
        }
        fetchData();
    }

    const fetchData = async () => {
        setTableLoading(true);
        const response = await getBankAccounts(accessToken,{
            ...tableParams,
            types: types
        });
        if (response.success) {
            setDataSource(response.data.data);
            setTableParams({
                ...tableParams,
                pagination: {
                    ...tableParams.pagination,
                    total: response.data.total
                },
            });
            setSummaryData({
                totalBalance: response.data.totalBalance
            })
        }
        setTableLoading(false);
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            sorter,
        });
        // `dataSource` is useless since `pageSize` changed
        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            setDataSource([]);
        }
    };

    const isEditing = (record) => record.uuid === editingKey;

    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: col.inputType,
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
                filters: col.filters ?? []
            }),
        };
    });

    const handleSearch = () => {
        const params = formSearch.getFieldsValue();
        setTableParams({
            ...tableParams,
            search: {
                ...params
            }
        });
    }

    useEffect(() => {
        fetchData();
    }, [
        tableParams.pagination?.current,
        tableParams.pagination?.pageSize,
        tableParams?.sorter,
        tableParams?.search,
        JSON.stringify(tableParams.filters),
        types
    ]);

    useEffect(() => {
        onRefresh.current = fetchData;
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            const response = await getBankTransfer(accessToken);
            if (response.success) {
                setBankTransfer(response.data);
            }
        }
        fetchData().finally();
    }, []);

    return (
        <>
            <Search
                form={formSearch}
                items={items}
                onSearch={handleSearch}
            />
            <Form
                form={form}
                component={false}
            >
                <Table
                    rowKey={'uuid'}
                    columns={mergedColumns}
                    // rowSelection={rowSelection}
                    bordered={true}
                    scroll={{
                        x: 2800
                    }}
                    loading={tableLoading}
                    dataSource={dataSource}
                    onChange={handleTableChange}
                    pagination={tableParams.pagination}
                    components={{
                        body: {
                            cell: EditTableCell
                        }
                    }}
                    summary={(data) => {
                        return (
                            <Table.Summary.Row>
                                <Table.Summary.Cell index={0} colSpan={4}>
                                    Tổng số dư
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={1} align={'center'} colSpan={1}>
                                    <Tag color={'blue'}>{numberFormat(summaryData.totalBalance)}</Tag>
                                </Table.Summary.Cell>
                            </Table.Summary.Row>
                        );
                    }}
                />
            </Form>
            <Modal
                open={openModal}
                onOk={() => setOpenModal(false)}
                onCancel={() => setOpenModal(false)}
                title={'Hiển thị mã QR CODE'}
            >
                <Flex
                    justify={'center'}
                    align={'center'}
                >
                    <QRCode value={qrCode}/>
                </Flex>
            </Modal>
            <BankAccountTransaction
                open={openModalTransaction}
                setOpen={setOpenModelTransaction}
                dataSource={dataTransaction}
            />
            <Modal
                open={openModalDevice}
                onOk={formDevice.submit}
                onCancel={() => setOpenModalDevice(false)}
                title={'Cài đặt thiết bị liên kết'}
                okText={'Xác nhận'}
            >
                <Form
                    layout={'vertical'}
                    form={formDevice}
                    onFinish={handleSubmitDevice}
                >
                    <Form.Item
                        name={'deviceName'}
                        label={'Tên thiết bị'}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        name={'deviceId'}
                        label={'ID thiết bị'}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        name={'platformVersion'}
                        label={'Phiên bản Android'}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        name={'pin'}
                        label={'Mã pin'}
                    >
                        <Input.Password/>
                    </Form.Item>
                </Form>
            </Modal>
            <FormDrawer
                width={600}
                title={'Chuyển tiền'}
                open={openDrawerTransfer}
                onClose={() => setOpenDrawerTransfer(false)}
                items={itemTransfer}
                form={formTransfer}
                onSubmit={handleSubmitTransfer}
                onChange={handleChangeTransfer}
            />
        </>
    );
}

export default BankAccountTable;