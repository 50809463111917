import {Button, Col, Drawer, Form, Input, InputNumber, Row, Select} from "antd";

function FormDrawer({ open, onClose, form, onSubmit, onChange, items, title, loading = false, width = 500}) {
    return (
        <Drawer
            title={title}
            open={open}
            onClose={onClose}
            width={width}
        >
            <Form form={form} layout={'vertical'} onFinish={onSubmit} onChange={onChange}>
                <Row gutter={16}>
                    {items.map((value, index) => {
                        return (
                            <Col key={index} xs={24} sm={24} md={24} lg={24} xl={12}>
                                <Form.Item
                                    initialValue={value.initValue}
                                    name={value.name}
                                    label={value.label}
                                    rules={[
                                        {
                                            required: value.required,
                                        }
                                    ]}
                                >
                                {(() => {
                                   if (value.type === 'text') {
                                       return (
                                           <Input
                                               placeholder={value.placeholder}
                                               disabled={value.disabled}
                                           />
                                       )
                                   }
                                   else if (value.type === 'password') {
                                       return (
                                           <Input.Password
                                               placeholder={value.placeholder}
                                           />
                                       )
                                   }
                                   else if (value.type === 'select') {
                                       return (
                                           <Select
                                               options={value.options}
                                               placeholder={value.placeholder}
                                               showSearch={true}
                                               optionFilterProp={'label'}
                                               onChange={onChange}
                                           />
                                       )
                                   }
                                   else if (value.type === 'number') {
                                       return (
                                           <InputNumber
                                               style={{
                                                   width: '100%'
                                               }}
                                               placeholder={value.placeholder}
                                               min={0}
                                               disabled={value.disabled}
                                               formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                               parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                           />
                                       )
                                   }
                                })()}
                                </Form.Item>
                            </Col>
                        )
                    })}
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Button loading={loading} type={'primary'} htmlType={'submit'}>Xác nhận</Button>
                    </Col>
                </Row>
            </Form>
        </Drawer>
    )
}

export default FormDrawer;