import {Button, Card, Col, DatePicker, Flex, Form, Input, Row, Select} from "antd";
import classnames from "classnames/bind";
import styles from './Search.module.scss'
import {SearchOutlined} from "@ant-design/icons";
import dayjs from 'dayjs'

const cx = classnames.bind(styles);

function Search({items,form,onSearch}) {
    return (
        <Form
            form={form}
        >
            <Row
                gutter={16}
            >
                {items.map((item,index) => {
                    const defaultRange = [dayjs().startOf('day'), dayjs().endOf('day')];
                    return (
                        <Col xs={24} sm={24} md={4} lg={4} xl={4} key={index}>
                            <Form.Item
                                name={item.name}
                                initialValue={item.type === 'date' ? defaultRange : ''}
                            >
                                {(() => {
                                    if (item.type === 'text') {
                                        return (
                                            <Input
                                                placeholder={item.placeholder}
                                            />
                                        )
                                    }
                                    else if (item.type === 'date') {
                                        return (
                                            <DatePicker.RangePicker
                                                showTime={true}
                                                presets={[
                                                    {
                                                        label: 'Hôm qua',
                                                        value: [dayjs().add(-1, 'd'), dayjs()],
                                                    },
                                                    {
                                                        label: 'Một tuần',
                                                        value: [dayjs().add(-14, 'd'), dayjs()],
                                                    },
                                                    {
                                                        label: 'Một tháng',
                                                        value: [dayjs().add(-30, 'd'), dayjs()],
                                                    }
                                                ]}
                                            />
                                        );
                                    }
                                    else if (item.type === 'search') {
                                        return (
                                            <Select
                                                showSearch
                                                placeholder={item.placeholder}
                                                defaultActiveFirstOption={false}
                                                suffixIcon={<SearchOutlined/>}
                                                filterOption={false}
                                                notFoundContent={null}
                                                onSearch={item.onSearch}
                                                options={(item.options || []).map((d) => ({
                                                    value: d.value,
                                                    label: d.text,
                                                }))}
                                            />
                                        )
                                    }
                                })()}
                            </Form.Item>
                        </Col>
                    )
                })}
                <Col xs={24} sm={24} md={24} lg={4} xl={4}>
                    <Button
                        type={'primary'}
                        className={cx('btn')}
                        icon={<SearchOutlined/>}
                        onClick={onSearch}
                    >Tìm kiếm</Button>
                </Col>
            </Row>
        </Form>
    )
}

export default Search;