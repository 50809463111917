import {Flex, Tag} from "antd";
import {numberFormat} from "../../helpers/helpers";

function AmountLimit({amount,total}) {
    const remaining = total - amount;
    return (
        <Flex vertical={true} justify={'center'} align={'center'} gap={'small'}>
            <Tag>{numberFormat(amount)} VNĐ</Tag>
            <Tag>Còn: {numberFormat(remaining)} VNĐ</Tag>
        </Flex>
    );
}

export default AmountLimit;