import Container from "../components/Container/Container";
import {Breadcrumb, Button, Card, Form, message, Space, Table, Tag, Typography} from "antd";
import {formatDateTime, numberFormat} from "../helpers/helpers";
import Search from "../components/Search/Search";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAdd} from "@fortawesome/free-solid-svg-icons";
import FormDrawer from "../components/FormDrawer/FormDrawer";
import {useContext, useEffect, useState} from "react";
import getBankWithdraw from "../api/withdraw/getBankWithdraw";
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";
import LoadingContext from "../contexts/LoadingContext";
import createWithdrawal from "../api/withdraw/createWithdrawal";
import getWithdrawals from "../api/withdraw/getWithdrawals";
import TextCopy from "../components/TextCopy";

function Withdraw() {
    const [form] = Form.useForm();
    const [formWithdraw] = Form.useForm();
    const [open,setOpen] = useState(false);
    const accessToken = useAuthHeader();
    const [banks,setBanks] = useState([]);
    const {setLoading} = useContext(LoadingContext);
    const [messageApi, contextHolder] = message.useMessage();
    const [dataSource,setDataSource] = useState([]);
    const [tableLoading,setTableLoading] = useState(false);
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });

    const items = [
        {
            name: 'createdAt',
            type: 'date'
        }
    ]
    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            align: 'center',
            sorter: true
        },
        {
            title: 'Ngân hàng',
            dataIndex: 'bankName'
        },
        {
            title: 'Số tài khoản',
            dataIndex: 'accountNumber',
            align: 'center',
            render: (value) => {
                return (
                    <TextCopy value={value}/>
                )
            }
        },
        {
            title: 'Tên tài khoản',
            dataIndex: 'accountName',
            align: 'center',
            render: (value) => {
                return <Tag>{value}</Tag>
            }
        },
        {
            title: 'Số tiền',
            dataIndex: 'amount',
            sorter: true,
            align: 'center',
            render: (value) => {
                return numberFormat(value);
            }
        },
        {
            title: 'Ghi chú',
            dataIndex: 'note',
            width: '200px',
            align: 'center'
        },
        {
            title: 'Trạng thái',
            dataIndex: 'state',
            align: 'center',
            filters: [
                {
                    text: 'Chờ duyệt',
                    value: 'pending'
                },
                {
                    text: 'Đã duyệt',
                    value: 'processed'
                },
                {
                    text: 'Bị từ chối',
                    value: 'refused'
                }
            ],
            render: (value) => {
                if (value === 'pending') {
                    return <Tag color={'yellow'}>Chờ duyệt</Tag>
                }
                else if (value === "processed") {
                    return <Tag color={'green'}>Đã duyệt</Tag>
                }
                else if (value === "refused") {
                    return <Tag color={'red'}>Bị từ chối</Tag>
                }
            }
        },
        {
            title: 'Thời gian tạo',
            dataIndex: 'createdAt',
            sorter: true,
            render: (value) => {
                return formatDateTime(value)
            }
        }
    ]

    const itemWithdraws = [
        {
            name: 'accountNumber',
            placeholder: 'Nhập số tài khoản',
            type: 'text',
            label: 'Số tài khoản',
            required: true
        },
        {
            name: 'accountName',
            placeholder: 'Nhập tên tài khoản',
            type: 'text',
            label: 'Tên tài khoản',
            required: true
        },
        {
            name: 'amount',
            placeholder: 'Nhập số tiền',
            type: 'number',
            label: 'Số tiền',
            required: true
        },
        {
            name: 'bankCode',
            placeholder: 'Chọn ngân hàng',
            type: 'select',
            label: 'Ngân hàng nhận',
            required: true,
            options: (banks || []).map((item) => {
                return {
                    value: item.bankCode,
                    label: `${item.bankName} - ${item.displayName} (${item.shortName})`
                }
            })
        },
        {
            name: 'note',
            placeholder: 'Nhập ghi chú',
            type: 'text',
            label: 'Ghi chú',
            required: false
        },
    ]
    const handleSubmitWithdraw = (values) => {
        setLoading(true);
        const postData = async () => {
            const response = await createWithdrawal(accessToken,values);
            if (!response.success) {
                message.open({
                    type: 'error',
                    content: response.message
                });
                return;
            }
            fetchDataTable();
        }
        postData().finally(()=> {
            setLoading(false);
        });
    }

    const fetchDataTable = async () => {
        setTableLoading(true);
        const response = await getWithdrawals(accessToken,tableParams);
        if (response.success) {
            setDataSource(response.data.data);
            setTableParams({
                ...tableParams,
                pagination: {
                    ...tableParams.pagination,
                    total: response.data.total
                },
            });
        }
        setTableLoading(false);
    }

    const handleSearch =  () => {
        const params = form.getFieldsValue();
        setTableParams({
            ...tableParams,
            search: {
                ...params
            }
        })
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            sorter,
        });
    };

    useEffect(() => {
        const fetchData = async () => {
            const response = await getBankWithdraw(accessToken);
            if (response.success) {
                setBanks(response.data);
            }
        }
        fetchData();
    }, []);

    useEffect(() => {
        fetchDataTable();
    }, [
        tableParams.pagination?.current,
        tableParams.pagination?.pageSize,
        tableParams?.sorter,
        tableParams?.search,
        JSON.stringify(tableParams.filters),
    ])

    return (
        <Container>
            {contextHolder}
            <Space
                style={{
                    marginBottom: 16,
                }}
            >
                <Button type={'primary'} ghost={true} onClick={() => setOpen(true)} icon={<FontAwesomeIcon icon={faAdd}/>}>Tạo đơn rút</Button>
            </Space>
            <Search
                items={items}
                form={form}
                onSearch={handleSearch}
            />
            <Table
                rowKey={'id'}
                columns={columns}
                dataSource={dataSource}
                bordered={true}
                scroll={{
                    x: 1800,
                }}
                loading={tableLoading}
                pagination={tableParams.pagination}
                onChange={handleTableChange}
            />
            <FormDrawer
                open={open}
                onClose={() => setOpen(false)}
                items={itemWithdraws}
                form={formWithdraw}
                onSubmit={handleSubmitWithdraw}
                title={'Tạo đơn rút tiền'}
            />
        </Container>
    )
}

export default Withdraw;