import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";
import {useContext, useEffect, useState} from "react";
import LoadingContext from "../contexts/LoadingContext";
import {Form, Table, Tag} from "antd";
import {formatDateTime, numberFormat} from "../helpers/helpers";
import getStatistics from "../api/statistic/getStatistics";
import Container from "../components/Container/Container";
import Search from "../components/Search/Search";

function Statistic() {
    const accessToken  = useAuthHeader();
    const {setLoading} = useContext(LoadingContext);
    const [dataSource,setDataSource] = useState([]);
    const [tableLoading, setTableLoading] = useState(false);
    const [form] = Form.useForm();
    const [summaryData, setSummaryData] = useState({
        totalAmountPayIn: 0,
        totalAmountPayOut: 0,
        totalActualAmountPayIn: 0,
        totalActualAmountPayOut: 0
    });
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });

    const items = [
        {
            name: 'createdAt',
            type: 'date',
        }
    ];

    const columns = [
        {
            title: 'ID',
            dataIndex: 'uuid',
            align: 'center'
        },
        {
            title: 'Tổng tiền IN',
            dataIndex: 'totalAmountPayIn',
            align: 'center',
            width: '240px',
            sorter: true,
            render: (value) => {
                return numberFormat(value);
            }
        },
        {
            title: 'Tổng tiền OUT',
            dataIndex: 'totalAmountPayOut',
            align: 'center',
            width: '240px',
            sorter: true,
            render: (value) => {
                return numberFormat(value);
            }
        },
        {
            title: 'Tổng tiền thực nhận IN',
            dataIndex: 'totalActualAmountPayIn',
            align: 'center',
            width: '240px',
            sorter: true,
            render: (value) => {
                return numberFormat(value);
            }
        },
        {
            title: 'Tổng tiền thực OUT',
            dataIndex: 'totalActualAmountPayOut',
            align: 'center',
            width: '240px',
            sorter: true,
            render: (value) => {
                return numberFormat(value);
            }
        },
        {
            title: 'Ngày',
            dataIndex: 'date',
            align: 'center',
            width: '240px',
            sorter: true,
            render: (value) => {
                return formatDateTime(value)
            }
        }
    ];

    const fetchData = async () => {
        setTableLoading(true);
        const response = await getStatistics(accessToken,tableParams);
        if (response.success) {
            setDataSource(response.data.data);
            setTableParams({
                ...tableParams,
                pagination: {
                    ...tableParams.pagination,
                    total: response.data.total
                },
            });
            setSummaryData({
                totalAmountPayIn: response.data.totalAmountPayIn,
                totalAmountPayOut: response.data.totalAmountPayOut,
                totalActualAmountPayIn: response.data.totalActualAmountPayIn,
                totalActualAmountPayOut: response.data.totalActualAmountPayOut
            })
        }
        setTableLoading(false);
    }

    const handleSearch =  () => {
        const params = form.getFieldsValue();
        setTableParams({
            ...tableParams,
            search: {
                ...params
            }
        })
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            sorter,
        });
    };

    useEffect(() => {
        fetchData().finally();
    }, [
        tableParams.pagination?.current,
        tableParams.pagination?.pageSize,
        JSON.stringify(tableParams.sorter),
        JSON.stringify(tableParams.search),
        JSON.stringify(tableParams.filters),
    ]);

    return (
        <Container>
            <Search
                form={form}
                items={items}
                onSearch={handleSearch}
            />
            <Table
                columns={columns}
                dataSource={dataSource}
                loading={tableLoading}
                bordered={true}
                scroll={{
                    x: 1600
                }}
                onChange={handleTableChange}
                pagination={tableParams.pagination}
                summary={(data) => {

                    return (
                        <Table.Summary.Row>
                            <Table.Summary.Cell index={0} colSpan={1}>
                                Thống kê
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={1} align={'center'}>
                                <Tag color={'blue'}>{numberFormat(summaryData.totalAmountPayIn)}</Tag>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={1} align={'center'}>
                                <Tag color={'blue'}>{numberFormat(summaryData.totalAmountPayOut)}</Tag>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={1} align={'center'}>
                                <Tag color={'blue'}>{numberFormat(summaryData.totalActualAmountPayIn)}</Tag>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={1} align={'center'}>
                                <Tag color={'blue'}>{numberFormat(summaryData.totalActualAmountPayOut)}</Tag>
                            </Table.Summary.Cell>
                        </Table.Summary.Row>
                    );
                }}
            />
        </Container>
    )
}

export default Statistic;