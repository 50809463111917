import {Tag} from "antd";

function BankAccountType({type}) {
    let value;
    switch (type) {
        case 'in':
            value = 'IN';
            break;
        case 'out':
            value = 'OUT';
            break;
    }
    return (
        <>
            <Tag>{value}</Tag>
        </>
    )
}

export default BankAccountType;