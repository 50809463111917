import {Menu} from "antd";
import {DashboardOutlined, UploadOutlined, VideoCameraOutlined} from "@ant-design/icons";
import React, {useContext, useState} from "react";
import {Layout} from "antd";
import classnames from "classnames/bind";
import styles from './SiderBar.module.scss'
import {appRoutes} from "../../routes/web";
import {Link} from "react-router-dom";
import SiderBarContext from "../../contexts/SiderBarContext";
import LabelMenu from "./LabelMenu/LabelMenu";
import ItemIcon from "./ItemIcon/ItemIcon";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
const {Sider}= Layout;
const cx = classnames.bind(styles);
function SiderBar({collapsed}) {
    const [keyActive,setKeyActive] = useContext(SiderBarContext);
    const userData = useAuthUser();

    return (
        <Sider
            trigger={null}
            collapsible={true}
            collapsed={collapsed}
            breakpoint={'lg'}
            style={{
                overflow: 'auto',
                height: '100vh',
                position: 'fixed',
                left: 0,
                top: 0,
                bottom: 0,
            }}
        >
            <div className={cx('logo-vertical')} />
            <Menu
                theme="dark"
                mode="inline"
                defaultSelectedKeys={[keyActive]}
                items={appRoutes.map((value) => {
                    if (value.roles.includes(userData.role)) {
                        if (value.type === 'item') {
                            return {
                                key: value.key,
                                label: <LabelMenu><Link to={value.path} onClick={() => setKeyActive(value.key)}>{value.label}</Link></LabelMenu>,
                                icon: <ItemIcon>{value.icon}</ItemIcon>,
                            }
                        }
                        return {
                            type: value.type,
                            label: value.label
                        }
                    }
                })}
            />
        </Sider>
    )
}

export default SiderBar;