import {Modal, Table, Tag, Typography} from "antd";
import TextCopy from "../../TextCopy";
import {convertUnixTimestampToDateTime, numberFormat} from "../../../helpers/helpers";

function BankAccountTransaction({ dataSource, open, setOpen }) {
    const columns = [
        {
            title: 'Mã GD',
            dataIndex: 'transactionId',
            align: 'center',
            width: '200px',
            render: (value) => {
                return <TextCopy value={value}/>
            }
        },
        {
            title: 'Số tiền',
            dataIndex: 'amount',
            align: 'center',
            width: '100px',
            render: (value) => {
                return numberFormat(value);
            }
        },
        {
            title: 'Phí',
            dataIndex: 'fee',
            align: 'center',
            width: '100px',
        },
        {
            title: 'Chuyển/Nhận',
            dataIndex: 'io',
            align: 'center',
            width: '150px',
            render: (value) => {
                if (value === 1) {
                    return <Tag color={'success'}>Nhận</Tag>
                }
                return <Tag color={'blue'}>Chuyển</Tag>
            }
        },
        {
            title: 'Nội dung',
            dataIndex: 'content',
            render: (value) => {
                return <Typography.Paragraph ellipsis={{
                    rows: 1,
                    expandable: true,
                    symbol: 'more',
                }}>{value}</Typography.Paragraph>
            }
        },
        {
            title: 'Trạng thái',
            dataIndex: 'status',
            align: 'center',
            width: '150px',
            render: (value) => {
                if (value === true) {
                    return <Tag color={'success'}>Thành công</Tag>
                }
                return <Tag color={'red'}>Lỗi</Tag>
            }
        },
        {
            title: 'Thời gian',
            dataIndex: 'time',
            align: 'center',
            width: '200px',
            render: (value) => {
                return convertUnixTimestampToDateTime(value);
            }
        }
    ]

    return (
        <Modal
            open={open}
            onCancel={() => setOpen(false)}
            width={'80%'}
            title={'Danh sách giao dịch'}
            footer={null}
        >
            <Table
                rowKey={'transactionId'}
                columns={columns}
                dataSource={dataSource}
                scroll={{
                    x: 2000,
                }}
                bordered={true}
            />
        </Modal>
    )
}

export default BankAccountTransaction;