import Container from "../components/Container/Container";
import {Button, Form, Space, Table, Tag} from "antd";
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";
import {useContext, useEffect, useState} from "react";
import {formatDateTime, numberFormat} from "../helpers/helpers";
import TextCopy from "../components/TextCopy";
import Search from "../components/Search/Search";
import getPayOuts from "../api/pay-out/getPayOuts";
import LoadingContext from "../contexts/LoadingContext";
import getBanks from "../api/bank/getBanks";

function PayOut() {
    const accessToken = useAuthHeader();
    const [tableLoading,setTableLoading] = useState(false);
    const [form] = Form.useForm();
    const [dataSource,setDataSource] = useState([]);
    const [summaryData, setSummaryData] = useState({
        totalAmount: 0,
        totalFee: 0,
        totalActualAmount: 0,
    });
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });

    const items =  [
        {
            name: 'uuid',
            placeholder: 'Mã đơn',
            type: 'text'
        },
        {
            name: 'requestId',
            placeholder: 'Mã tra soát',
            type: 'text'
        },
        {
            name: 'accNo',
            placeholder: 'Số tài khoản',
            type: 'text'
        },
        {
            name: 'createdAt',
            placeholder: 'Thời gian',
            type: 'date'
        },
    ];

    const columns = [
        {
            title: 'ID',
            dataIndex: 'uuid',
            align: 'center',
            render: (value) => {
                return <TextCopy value={value}/>
            }
        },
        {
            title: 'Mã tra soát',
            dataIndex: 'requestId',
            align: 'center',
            render: (value) => {
                return <TextCopy value={value}/>
            }
        },
        {
            title: 'Ngân hàng',
            dataIndex: 'bankName',
            align: 'center'
        },
        {
            title: 'Số tài khoản',
            dataIndex: 'accNo',
            align: 'center',
            render: (value) => {
                return <TextCopy value={value}/>
            }
        },
        {
            title: 'Tên tài khoản',
            dataIndex: 'accName',
            align: 'center',
            render: (value) => {
                return <Tag>{value}</Tag>
            }
        },
        {
            title: 'Số tiền',
            dataIndex: 'amount',
            align: 'center',
            sorter: true,
            render: (value) => {
                return numberFormat(value)
            }
        },
        {
            title: 'Phí',
            dataIndex: 'fee',
            align: 'center',
            render: (value) => {
                return numberFormat(value, 2)
            }
        },
        {
            title: 'Tiền trừ',
            dataIndex: 'actualAmount',
            align: 'center',
            sorter: true,
            render: (value) => {
                return numberFormat(value)
            }
        },
        {
            title: 'Nội dung',
            dataIndex: 'content',
            align: 'center'
        },
        {
            title: 'Trạng thái',
            dataIndex: 'status',
            align: 'center',
            filters: [
                {
                    text: 'Đã hoàn thành',
                    value: true
                },
                {
                    text: 'Chưa hoàn thành',
                    value: false
                }
            ],
            render: (value) => {
                if(value) {
                    return <Tag color={'green'}>Đã hoàn thành</Tag>
                }
                return <Tag color={'red'}>Chưa hoàn thành</Tag>
            }
        },
        {
            title: 'Tình trạng',
            dataIndex: 'statusCode',
            align: 'center',
            render: (value) => {
                if (value === 0) {
                    return <Tag color={'green'}>Thành công</Tag>
                }else if (value === 1) {
                    return <Tag color={'yellow'}>Đang chờ</Tag>
                }else if(value === 2) {
                    return <Tag color={'blue'}>Kiểm tra tên</Tag>
                }else if (value === 3) {
                    return <Tag color={'blue'}>Tạo lệnh</Tag>
                }else if (value === 4) {
                    return <Tag color={'blue'}>Duyệt lệnh</Tag>
                }else if (value === -5) {
                    return <Tag color={'red'}>Tên không khớp</Tag>
                }
            }
        },
        {
            title: 'Trạng thái CB',
            dataIndex: 'isCallback',
            align: 'center',
            render: (value) => {
                if (value) {
                    return <Tag color={'success'}>Đã CALLBACK</Tag>
                }
                return <Tag color={'yellow'}>Chưa CALLBACK</Tag>
            }
        },
        {
            title: 'Hoàn tiền',
            dataIndex: 'isRefunded',
            align: 'center',
            render: (value) => {
                if (value) {
                    return <Tag color={'success'}>Đã hoàn</Tag>
                }
            }
        },
        {
            title: 'Thời gian tạo',
            dataIndex: 'createdAt',
            sorter: true,
            align: 'center',
            width: '180px',
            render: (value,record)  => {
                return (
                    <Tag>{formatDateTime(value)}</Tag>
                )
            }
        },
        {
            title: 'Thời gian hoàn thành',
            dataIndex: 'completedAt',
            sorter: true,
            align: 'center',
            width: '180px',
            render: (value,record)  => {
                if (value) {
                    return (
                        <Tag>{formatDateTime(value)}</Tag>
                    )
                }
            }
        },
        {
            title: 'Hành động',
            dataIndex: 'operation',
            align: 'center',
            render: (_,record) => {
                return (
                    <Space>
                    </Space>
                )
            }
        }
    ];

    const handleSearch = () => {
        const params = form.getFieldsValue();
        setTableParams({
            ...tableParams,
            search: {
                ...params
            }
        })
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            sorter,
        });
    };

    const fetchDataPayOuts = async () => {
        setTableLoading(true);
        const response = await getPayOuts(accessToken, tableParams);
        if (response.success) {
            setDataSource(response.data.data);
            setTableParams({
                ...tableParams,
                pagination: {
                    ...tableParams.pagination,
                    total: response.data.total
                },
            });
            setSummaryData({
                totalAmount: response.data.totalAmount,
                totalFee: response.data.totalFee,
                totalActualAmount: response.data.totalActualAmount,
            })
        }
        setTableLoading(false);
    }

    useEffect(() => {
        fetchDataPayOuts().finally();
    }, [
        tableParams.pagination?.current,
        tableParams.pagination?.pageSize,
        tableParams?.sorter,
        tableParams?.search,
        JSON.stringify(tableParams.filters)
    ]);

    return (
        <Container>
            <Search
                items={items}
                form={form}
                onSearch={handleSearch}
            />
            <Table
                rowKey={'uuid'}
                columns={columns}
                scroll={{
                    x: 2200,
                }}
                bordered={true}
                loading={tableLoading}
                dataSource={dataSource}
                pagination={tableParams.pagination}
                onChange={handleTableChange}
                summary={(data) => {
                    return (
                        <Table.Summary.Row>
                            <Table.Summary.Cell index={0} colSpan={5}>
                                Thống kê
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={1} align={'center'} colSpan={1}>
                                <Tag color={'blue'}>{numberFormat(summaryData.totalAmount)}</Tag>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={1} align={'center'} colSpan={1}>
                                <Tag color={'blue'}>{numberFormat(summaryData.totalFee)}</Tag>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={2} align={'center'} colSpan={1}>
                                <Tag color={'blue'}>{numberFormat(summaryData.totalActualAmount)}</Tag>
                            </Table.Summary.Cell>
                        </Table.Summary.Row>
                    );
                }}
            />
        </Container>
    )
}

export default PayOut;