import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";
import {useEffect, useState} from "react";
import {Form, Table, Tag} from "antd";
import ReadMoreText from "../../components/ReadMoreText";
import {formatDateTime} from "../../helpers/helpers";
import getLogs from "../../api/admin/log-webhook/getLogs";
import Container from "../../components/Container/Container";
import Search from "../../components/Search/Search";
import searchPartner from "../../api/partner/searchPartner";

function Hook() {
    const accessToken = useAuthHeader();
    const [tableLoading,setTableLoading] = useState(false);
    const [form] = Form.useForm();
    const [dataSource,setDataSource] = useState([]);
    const [options,setOptions] = useState([]);
    const [query,setQuery] = useState('');
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });

    const items = [
        {
            name: 'userId',
            type: 'search',
            placeholder: 'Nhập tên đối tác',
            options: options,
            onSearch: (value) => {
                setQuery(value)
            }
        },
        {
            name: 'createdAt',
            type: 'date',
        }
    ];

    const columns = [
        {
            title: 'ID',
            dataIndex: 'uuid',
            align: 'center'
        },
        {
            title: 'Đối tác',
            dataIndex: 'partnerName',
            align: 'center',
            render: (value) => {
                return <Tag>{value}</Tag>
            }
        },
        {
            title: 'Đường dẫn',
            dataIndex: 'webhookUrl'
        },
        {
            title: 'Mã code',
            dataIndex: 'statusCode',
            align: 'center',
            render: (value) => {
                if (value >= 200 && value <= 299) {
                    return <Tag color={'green'}>{value}</Tag>
                }
                return <Tag color={'red'}>{value}</Tag>
            }
        },
        {
            title: 'Kiểu',
            dataIndex: 'httpVerb',
            align: 'center',
            render: (value) => {
                return <Tag color={'blue'}>{value}</Tag>
            }
        },
        {
            title: 'Nội dung lỗi',
            dataIndex: 'errorMessage'
        },
        {
            title: 'Loại lỗi',
            dataIndex: 'errorType',
            align: 'center'
        },
        {
            title: 'Dữ liệu gửi',
            dataIndex: 'payload',
            render: (value) => {
                return <ReadMoreText text={JSON.stringify(value)} maxLength={50}/>
            }
        },
        {
            title: 'Thời gian',
            dataIndex: 'createdAt',
            sorter: true,
            align: 'center',
            render: (value) => {
                return formatDateTime(value)
            }
        }
    ];

    const fetchPartners = async () => {
        const response = await searchPartner(accessToken,query);
        if (response.success) {
            const data = response.data.map(item => ({
                value: item.userId,
                text: item.name
            }));
            setOptions(data)
        }
    }

    const fetchData = async () => {
        setTableLoading(true);
        const response = await getLogs(accessToken,tableParams);
        if (response.success) {
            setDataSource(response.data.data);
            setTableParams({
                ...tableParams,
                pagination: {
                    ...tableParams.pagination,
                    total: response.data.total
                },
            });
        }
        setTableLoading(false);
    }

    const handleSearch =  () => {
        const params = form.getFieldsValue();
        setTableParams({
            ...tableParams,
            search: {
                ...params
            }
        })
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            sorter,
        });
    };

    useEffect(() => {
        fetchData().finally();
    }, [
        tableParams.pagination?.current,
        tableParams.pagination?.pageSize,
        JSON.stringify(tableParams.sorter),
        JSON.stringify(tableParams.search),
        JSON.stringify(tableParams.filters),
    ]);

    useEffect(() => {
        fetchPartners();
    }, [query])
    return (
        <Container>
            <Search
                form={form}
                items={items}
                onSearch={handleSearch}
            />
            <Table
                columns={columns}
                dataSource={dataSource}
                bordered={true}
                onChange={handleTableChange}
                loading={tableLoading}
                pagination={tableParams.pagination}
                scroll={{
                    x: 2200,
                }}
            />
        </Container>
    )
}

export default Hook;